import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import { setClassNames } from 'ddc-classnames-js';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import VehicleCount from './VehicleCount';
import FilterItemList from './FilterItemList';
import FilterItem from './FilterItem';
import ClearFilters from './ClearFilters';
import AppliedFilters from './AppliedFilters';
import FilterModalButton from './FilterModalButton';
import InventorySort from './InventorySort';
import Switch from './Switch';
import {
	getPlaceholderLayout,
	getPaymentsEnabled
} from '../utilities/wiapiSettings';

const FiltersContainer = ({
	appliedFilters,
	invCount,
	isDesktop,
	isScrolled,
	navbarHeightCalc,
	totalCount,
	showPaymentsChecked,
	handleShowPaymentsUpdate
}) => {
	// TODO sort options should come from Web Inventory Service in the future
	const { sortOptions } =
		(isBrowser &&
			window?.DDC?.getProperty(
				'window.DDC.WidgetData.inventory-data-bus1.props',
				window
			)) ||
		[];

	// For US1325090, use New Relic to identify sites that are configured
	// to sort by the deprecated paymentLoan & paymentLease facets
	setNewRelicCustomAttribute(
		'srpHasDeprecatedPaymentSort',
		!!sortOptions?.some(({ value }) => /payment/.test(value))
	);

	const labels = useLabels();

	const placeholderLayout = getPlaceholderLayout();
	const paymentsEnabled = getPaymentsEnabled();

	const stickyClasses = ['bg-page-title fixed-top p-4'];
	const filterClasses = [
		'facet-filters d-flex align-items-baseline flex-wrap flex-nowrap-lg'
	];

	return placeholderLayout ? (
		<div
			className={setClassNames([
				filterClasses,
				isScrolled && !isDesktop ? stickyClasses : ''
			])}
			style={{
				top: isScrolled && !isDesktop ? navbarHeightCalc : ''
			}}
		>
			<div className="d-lg-flex align-items-baseline order-1">
				<FilterItemList showFirst>
					<VehicleCount
						count={invCount}
						hasFilters={!!appliedFilters.length}
					/>
					{appliedFilters.length > 0 &&
						isDesktop &&
						appliedFilters.map((filter) =>
							filter.values.map((item) => (
								<FilterItem
									{...item}
									key={[filter.id, item.value].join('-')}
									filterId={filter.id}
									filterLabel={filter.label}
									showRemoveButton={item.showRemoveButton}
								/>
							))
						)}
					{appliedFilters.length > 0 && isDesktop && (
						<ClearFilters buttonClasses="d-none d-lg-inline-block" />
					)}
				</FilterItemList>
				{/* Display for no results when no facets are selected */}
				{appliedFilters.length === 0 && isDesktop && totalCount === 0 && (
					<FilterItemList>
						<ClearFilters buttonClasses="d-none d-md-inline-block" />
					</FilterItemList>
				)}
				{appliedFilters.length > 0 && isDesktop && (
					<AppliedFilters
						isScrolled={isScrolled}
						appliedFilters={appliedFilters}
					/>
				)}
			</div>
			<div className="d-flex mb-4 order-0 w-100 d-lg-none">
				<div className="mr-4 flex-grow-1">
					<FilterModalButton
						sortOptions={sortOptions}
						count={invCount}
						appliedFilters={appliedFilters}
					/>
				</div>
				<div className="flex-grow-1">
					<button
						type="button"
						className="btn btn-default btn-block ddc-font-size-large font-weight-bold"
						onClick={() => {
							if (isBrowser && window.CAIEventBus) {
								window.CAIEventBus.emit('wallet:open');
							}
						}}
					>
						<i className="ddc-icon ddc-icon-mywallet" />{' '}
						{labels.get('MY_WALLET')}{' '}
					</button>
				</div>
			</div>
			<div className="d-flex ml-auto flex-shrink-0 flex-align-baseline order-1">
				{paymentsEnabled === true && (
					<div className="mb-0 d-flex">
						<Switch
							isOn={showPaymentsChecked}
							handleToggle={handleShowPaymentsUpdate}
							label={labels.get('SHOW_PAYMENTS')}
						/>
					</div>
				)}
			</div>
			<div className="flex-shrink-0 d-none d-lg-block ml-4 order-1">
				<InventorySort
					{...{ sortOptions }}
					displayStyle="transparent"
				/>
			</div>
		</div>
	) : (
		<div
			className={setClassNames([
				filterClasses,
				isScrolled && !isDesktop ? stickyClasses : ''
			])}
			style={{
				top: isScrolled && !isDesktop ? navbarHeightCalc : ''
			}}
		>
			<div className="d-lg-flex align-items-baseline">
				<FilterItemList showFirst>
					<VehicleCount
						count={invCount}
						hasFilters={!!appliedFilters.length}
					/>
					{!!appliedFilters.length &&
						appliedFilters.length > 0 &&
						appliedFilters.map((filter) =>
							filter.values.map((item) => (
								<FilterItem
									{...item}
									key={[filter.id, item.value].join('-')}
									filterId={filter.id}
									filterLabel={filter.label}
									showRemoveButton={item.showRemoveButton}
								/>
							))
						)}
					{!!appliedFilters.length && appliedFilters.length > 0 && (
						<ClearFilters buttonClasses="d-none d-lg-inline-block" />
					)}
				</FilterItemList>
				{/* Display for no results when no facets are selected */}
				{!appliedFilters.length && totalCount === 0 && (
					<FilterItemList>
						<ClearFilters buttonClasses="d-none d-md-inline-block" />
					</FilterItemList>
				)}
				{isDesktop && (
					<AppliedFilters
						isScrolled={isScrolled}
						appliedFilters={appliedFilters}
					/>
				)}
			</div>
			<div className="ml-auto d-lg-none">
				<FilterModalButton
					sortOptions={sortOptions}
					count={invCount}
					appliedFilters={appliedFilters}
				/>
			</div>
			<div className="ml-auto flex-shrink-0 d-none d-lg-block">
				<InventorySort
					{...{ sortOptions }}
					displayStyle="transparent"
				/>
			</div>
		</div>
	);
};

FiltersContainer.propTypes = {
	appliedFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	invCount: PropTypes.oneOfType([
		PropTypes.oneOf([null]).isRequired,
		PropTypes.number.isRequired
	]).isRequired,
	isDesktop: PropTypes.bool.isRequired,
	isScrolled: PropTypes.bool.isRequired,
	navbarHeightCalc: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	showPaymentsChecked: PropTypes.bool.isRequired,
	handleShowPaymentsUpdate: PropTypes.func.isRequired
};

export default FiltersContainer;
