import React, { useRef, useEffect, useState } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import logger from 'ws-scripts/modules/logger';
import { useLabels, usePrefs, useFlags } from 'wsm-common-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { useWindowWidth } from 'wsm-srp-utilities';
import {
	getPaymentsEnabled,
	getPlaceholderLayout,
	getUserShowPayments,
	setHideTooltip
} from '../utilities/wiapiSettings';

import {
	formatAppliedFilters,
	getFeaturedPromotionFilter
} from '../utilities/filters';

import { getSuggestedFilters } from '../utilities/getSuggestedFilters';

import SuggestedFilterChips from '../components/SuggestedFilterChips';
import FiltersContainer from '../components/FiltersContainer';
import FilterItemListSkeleton from '../components/FilterItemListSkeleton';
import { useScrollEffect } from '../hooks/useScrollEffect';
import SpellcheckMsg from '../components/SpellcheckMsg';
import {
	usePubsub,
	useInventory,
	useFacets,
	evaluatePath
} from '../hooks/invDataHooks';

import {
	SHOW_PAYMENTS_LOCAL_STORAGE_KEY,
	SHOW_PAYMENTS_NOTIFICATION,
	WIDGET_NAME,
	FEATURES,
	NONE
} from '../global-constants';

import '../global-sass/style.scss';
import '../global-sass/switch.scss';
import ClosestDealerWrapper from '../components/ClosestDealerWrapper';

const waitForWIAPISettings = () => {
	return new Promise((resolve) => {
		let waitForSettings;
		const checkForSettings = () => {
			const placeholderLayout = getPlaceholderLayout();
			const paymentsEnabled = getPaymentsEnabled();
			const showPaymentsToggleEnabled = getUserShowPayments();

			if (
				typeof placeholderLayout !== 'undefined' &&
				typeof paymentsEnabled !== 'undefined' &&
				typeof showPaymentsToggleEnabled !== 'undefined'
			) {
				clearInterval(waitForSettings);
				resolve(true);
			}
		};
		waitForSettings = setInterval(checkForSettings, 50);
	});
};

const getSuggestionType = (
	enableHLFacet,
	showSuggestedFilters,
	suggestedFiltersFlag
) => {
	if (!suggestedFiltersFlag) {
		return enableHLFacet === 'gvOption' ? FEATURES : NONE;
	}
	return showSuggestedFilters;
};

const Widget = () => {
	const labels = useLabels();
	const { enableHLFacet, showSuggestedFilters } = usePrefs();
	const stickyFiltersRef = useRef(null);
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [useOriginalSearchTerm, setUseOriginalSearchTerm] = useState(false);
	const [showPaymentsChecked, setShowPaymentsChecked] = useState(false);
	const flags = useFlags();

	const pubsub = usePubsub();

	const {
		// isLoading: isInventoryLoading,
		// isError: isInventoryError,
		payload: inventory
	} = useInventory();

	const {
		isLoading: isFacetsLoading,
		// isError: isFacetsError,
		payload: facets
	} = useFacets();

	// Update the state of the Show Payments toggle.
	const handleShowPaymentsUpdate = (override, forcePopup = false) => {
		const useOverride = typeof override !== 'object';

		setShowPaymentsChecked((prevState) => {
			// Use the value passed in, if present.
			// Otherwise just toggle the state of the switch.
			const newState = useOverride ? override : !prevState;
			if (isBrowser) {
				// Save the state in local storage.
				window.localStorage.setItem(
					SHOW_PAYMENTS_LOCAL_STORAGE_KEY,
					JSON.stringify({ show: newState })
				);

				// Publish the current state of the toggle as an event for other
				// widgets to listen for when deciding how to render their content.
				if (pubsub) {
					pubsub.publish(SHOW_PAYMENTS_NOTIFICATION, {
						show: newState,
						source: WIDGET_NAME,
						isOverride: useOverride,
						forcePopup
					});
				}
			}
			return newState;
		});
	};
	const isStickyDisabled =
		(isBrowser &&
			window.DDC?.siteProperties?.templateHeaderDisableSticky) ||
		false;

	let navbarHeightCalc = 0;

	const width = useWindowWidth();
	const isDesktop = width > 992;

	const alertBannerVisible = !isDesktop
		? isBrowser &&
		  document.querySelector(
				'div[data-widget-id="template-alert1"] .content-alert-banner-container'
		  )
		: null;

	const pageHeaderHeight =
		isBrowser && document.querySelector('.page-header')?.offsetHeight;
	const navbarHeight =
		!isDesktop && isStickyDisabled && !alertBannerVisible
			? pageHeaderHeight
			: 0;

	const navbarHeightShrink = !isDesktop
		? isBrowser &&
		  document.querySelector('.navbar-default.sticky-header-nav')
		: null;

	const navbarHeightFixedMobile = !isDesktop
		? isBrowser &&
		  document.querySelector(
				'div[data-widget-id="template-navbar1"] .navbar.fixed'
		  )
		: null;

	if (navbarHeightShrink !== null) {
		navbarHeightCalc = navbarHeight + navbarHeightShrink.offsetHeight;
	} else if (
		navbarHeightFixedMobile !== null &&
		navbarHeight === 0 &&
		!alertBannerVisible
	) {
		navbarHeightCalc = navbarHeightFixedMobile.offsetHeight;
	} else if (
		navbarHeightFixedMobile !== null &&
		navbarHeight === 0 &&
		alertBannerVisible
	) {
		navbarHeightCalc = Math.max(
			pageHeaderHeight,
			navbarHeightFixedMobile.offsetHeight
		);
	} else {
		navbarHeightCalc = navbarHeight;
	}

	// Ensure the tooltip will remain hidden if the user is already viewing payments.
	if (getUserShowPayments()) {
		setHideTooltip();
	}

	useEffect(() => {
		if (pubsub) {
			waitForWIAPISettings().then(() => {
				// Set the toggle status that is defined in local storage.
				const showPaymentsToggleEnabled = getUserShowPayments();

				if (typeof showPaymentsToggleEnabled !== 'undefined') {
					handleShowPaymentsUpdate(showPaymentsToggleEnabled);
				}
				// Allow external events to change the state of the Show Payments toggle.
				pubsub.subscribe(SHOW_PAYMENTS_NOTIFICATION, (data) => {
					if (data.source !== WIDGET_NAME) {
						handleShowPaymentsUpdate(data.show);
					}
				});
			});
			// Send an event telling ws-inv-listing that it is safe to render the View Toggle portal.
			pubsub.publish('ws-inv-filters/toggle-portal-ready', true);

			pubsub.subscribe('ws-inv-data/clearSpellcheckMsg', () => {
				setUseOriginalSearchTerm(useOriginalSearchTerm);
			});
		}
	}, [pubsub]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const filters = facets?.filters;
		const featuredPromotion = evaluatePath(
			'DDC.InvData.urlParams.featuredPromotion'
		);
		if (filters)
			if (featuredPromotion && facets) {
				facets.filters.unshift(getFeaturedPromotionFilter(labels));
			}
		setAppliedFilters(formatAppliedFilters(filters, labels));
	}, [facets]); // eslint-disable-line react-hooks/exhaustive-deps

	const isScrolled = useScrollEffect(stickyFiltersRef, navbarHeightCalc);
	const suggestedFilters = getSuggestedFilters(
		getSuggestionType(
			enableHLFacet,
			showSuggestedFilters,
			flags['srp-show-suggested-filters']
		),
		facets
	);

	if (!isBrowser || isFacetsLoading) {
		return (
			<FilterItemListSkeleton
				willDisplaySuggestedFilters={showSuggestedFilters !== NONE}
			/>
		);
	}

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-filters error boundary.\n${error}`
				);
				const newError = new Error(
					`ws-inv-filters error boundary.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			{/* Suggested Filters */}
			{!!(
				(Array.isArray(suggestedFilters) && suggestedFilters.length) ||
				suggestedFilters.values?.length
			) && (
				<div className="facet-hl-container mt-n4 d-flex align-items-center">
					<SuggestedFilterChips filters={suggestedFilters} />
				</div>
			)}
			<ClosestDealerWrapper isMobile={!isDesktop} />
			<div className="facet-filters-ref" ref={stickyFiltersRef}>
				{/* Applied Filters Chips, Inventory Sort, etc. */}
				<FiltersContainer
					appliedFilters={appliedFilters}
					invCount={inventory?.pageInfo?.totalCount}
					isDesktop={isDesktop}
					isScrolled={isScrolled}
					navbarHeightCalc={navbarHeightCalc}
					totalCount={facets?.totalCount}
					showPaymentsChecked={showPaymentsChecked}
					handleShowPaymentsUpdate={handleShowPaymentsUpdate}
				/>
				{useOriginalSearchTerm && <SpellcheckMsg />}
			</div>
		</ErrorBoundary>
	);
};

export default Widget;
